<template>
<div id="login" class="main">

<div style="width: 90%; height: 75px; align-self: center;">
    <div style="display: flex; flex-direction: row;">
        <div v-if="!mainDisplay"style="height: 75px; width: 100%;">
            <div v-on:click="changeDisplay('main')" style="width: 75px; height: 75px">
               <img src="https://i.imgur.com/Az6Xs7i.png" style="max-width: 100%; height: auto; padding-top: 7px">
            </div>
        </div>
        <div style="height: 75px; width: 100%; display: flex; flex-direction: row; justify-content: flex-end">
            <div class="button" style="margin-right: 2%;" v-on:click="changeDisplay('contact')">
              Contact Us
            </div>
            <div class="button" v-on:click="changeDisplay('login')">
              Login
            </div>
        </div>
    </div>
</div>

<transition name="fade">
<div v-if="mainDisplay" style="width: 100%; height: 600px; align-self: center; display: flex; flex-direction: column; justify-content: flex-start">
  <div style="width: 300px; height: 300px; align-self: center; padding-right: 5%">
    <img src="~@/assets/ads.png" style="width: 400px; height: auto;">
  </div>


  <div>
    <h2> Access the Advanced Distribution Services retail website at: </h2>
    <a href="https://www.thehydroemporium.com/" style="text-decoration: none; color: green"><h1>The Hydro Emporium</h1></a>
  </div>

  <div>
    <h1 style="font-size: 5em; margin: 0; padding: 0; font-family: 'Gloria Hallelujah', cursive; color: #45567"> </h1>
    <div style="display: flex; flex-direction: column; justify-content: center">
    
    <!-- <div> 
  
    <h1 style="margin: 0; padding: 0; font-weight: 400; color: #75879A;  font-style: oblique;"> <span><img src="~@/assets/leaf.png" style="width: 25px; height: 25px"/></span>Take your grow to the next level   <span><img src="~@/assets/leaf2.png" style="width: 25px; height: 25px"/></span></h1>
    
    </div> -->
    <!-- <h1 style="align-self: center; font-family: 'Gloria Hallelujah', cursive;  color: #7FC3A4; cursor: pointer; font-size: 2.4em; text-decoration: underline; cursor: pointer;" v-on:click="changeDisplay('about')">
          Learn More
        </h1> -->
    </div>
  </div>
</div>
</transition> 

<transition name="fade">
<div v-if="aboutDisplay" style="width: 90%; height: 600px; align-self: center; display: flex; flex-direction: column">

<div style="width: 100%; height: 20%;">
  <h1> Advanced Distribution Services </h1>
  <h3>
    We are a commercial distributor of gardening products located in Hayward, CA. Our goal is to make it as easy as possible for you to do what you do best: growing your crops and your business. With our full suite of products, we offer a one stop solution for all your gardening needs.
  </h3>
</div>


<div style="width: 100%; height: 10%; padding: 3%">
  <h1 style="margin: 0; padding: 0; font-family: 'Gloria Hallelujah', cursive;"> Comprehensive Coverage From Seed to Crop </h1>
</div>
<div style="width: 100%; height: 70%; display: flex; flex-direction: row; justify-content: center">

<div style="width: 20%; height: auto">
    <img src="~@/assets/fert.png" style="width: 150px; height: 150px"/>
    <h2> Fertilizers </h2>
</div>

<div style="width: 20%; height: auto">
    <img src="~@/assets/bug.png" style="width: 150px; height: 150px"/>
    <h2> Pesticides / IPM </h2>
</div>

<div style="width: 20%; height: auto">
    <img src="~@/assets/shears.png" style="width: 150px; height: 150px"/>
    <h2> Shears and Clippers </h2>
</div>

<div style="width: 20%; height: auto">
    <img src="~@/assets/tools.png" style="width: 150px; height: 150px"/>
    <h2> Equipment </h2>
</div>

</div>


</div>
</transition>

<transition name="fade">
<div v-if="contactDisplay" style="width: 90%; height: 600px; align-self: center">
<div class="container">
  <form action="https://formspree.io/f/mzbkekql" method="POST">

    <label for="fname">First Name</label>
    <input type="text" id="fname" name="firstname" placeholder="Your name" v-model="form.fname">

    <label for="lname">Last Name</label>
    <input type="text" id="lname" name="lastname" placeholder="Your last name" v-model="form.lname">

    <label for="cname">Company Name</label>
    <input type="text" id="cname" name="cname" placeholder="Your company name" v-model="form.cname">
    
    <label for="phone">Phone number</label>
    <input type="tel" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="123-456-7890" v-model="form.phone">
    
    <label for="email">Email</label>
    <input type="text" placeholder="Enter Email" name="email" required v-model="form.email">

    <label for="subject">Subject</label>
    <textarea id="subject" name="subject" placeholder="Write something.." style="height:200px" v-model="form.subject"></textarea>

    <input type="submit" value="Submit">
 </form>
</div>
</div>
</transition>

<transition name="fade">
<div v-if="loginDisplay">
  <form @submit.prevent>
    <h1> Login </h1>
    <div style="width: 100%; height: 500px">
      <label for="email1">Email</label>
      <input v-model.trim="loginForm.email" type="text" placeholder="ads@adscorp.co" style="width: 193px; margin-left: 36px"/>
      <br>
      <label for="password1">Password</label>
      <input v-model.trim="loginForm.password" type="password" placeholder="******" style="padding: 10px; width: 172px; margin-left: 10px; border: lightgrey; border-radius: 4px"/>
      <br>
      <div style="padding-top: 2%">
      <button v-on:click="login">Log In</button>
      </div>
    </div>
  </form>
</div>
</transition>

<div>
<footer> <small>&copy; Copyright <span>{{year}}</span>, Advanced Distribution Services</small> </footer> 
</div>

</div>
</template>

<script>
import image from "./assets/A.jpg"
export default {
  mounted(){
      let d = new Date();
      this.year = d.getFullYear();
  },
  data() {
    return {
      image,
      loginForm: {
        email: '',
        password: ''
      },
      signupForm: {
        name: '',
        title: '',
        email: '',
        password: ''
      },
      form: {
        fname: '',
        lname: '',
        cname: '',
        phone: '',
        email: '',
        subject: ''
      },
      mainDisplay: 1,
      contactDisplay: 0,
      loginDisplay: 0,
      aboutDisplay: 0,
      year: ''
    }
  },
  methods: {
    login() {
        this.$store.dispatch('login', {
          email: this.loginForm.email,
          password: this.loginForm.password
        })
    },
    signup() {
      this.$store.dispatch('signup', {
        email: this.signupForm.email,
        password: this.signupForm.password,
        name: this.signupForm.name,
        title: this.signupForm.title
      })
    },
    toggleForm() {
        this.showLoginForm = 0
    },
    submitForm(){
      console.log(this.form)
    },
    changeDisplay(query){
      if(query == 'contact'){
        this.mainDisplay = 0
        this.contactDisplay = 1
        this.loginDisplay = 0
        this.aboutDisplay = 0
      } else if (query == 'login'){
        this.loginDisplay = 1
        this.contactDisplay = 0
        this.mainDisplay = 0
        this.aboutDisplay = 0
      } else if (query == 'main'){
        this.mainDisplay = 1
        this.contactDisplay = 0
        this.loginDisplay = 0
        this.aboutDisplay = 0
      } else if (query == 'about'){
        this.aboutDisplay = 1
        this.mainDisplay = 0
        this.contactDisplay = 0
        this.loginDisplay = 0 
      }
    }

  }
}
</script>

<style scoped>
input[type=text], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

input[type=tel], select, textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
input[type=submit] {
  background-color: #07ABA1;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: #45a049;
}

/* Add a background color and some padding around the form */
.container {
  border-radius: 5px;
  /* background-color: #f2f2f2; */
  padding: 20px;
  width: auto;
  height: 75%;
  overflow-y: auto;
}

#login {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.button {
  background-color: #7FC3A4; 
  color: #FFF; 
  width: 125px; 
  height: 35px; 
  padding-top: 10px;
  cursor: pointer;
  box-shadow: 3px 3px 3px 2px #FFF inset,  -3px -3px 4px 4px #FFF inset;
  font-family: 'Raleway', sans-serif;
}
.main {
  background-image: url('~@/assets/A3.png');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size:cover;
  background-size: 100% auto;
  border-radius: 5%;
  /* opacity: 0.4; */
  /* box-shadow: 0 0 5px 10px #F5F5F5; */
  box-shadow: 0px 10px 45px 85px #FFF inset, -25px -15px 45px 5px #FFF inset;
  margin-bottom: 15px;
}

/* Transition CSS */
.leaf {
  background-image: url('~@/assets/leaf.png');
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size:cover;
  background-size: 100% auto;
  align-self: center;
}

.fade-enter-active, .fade-leave-active {
transition-property: opacity;
transition-duration: .25s;
}

.fade-enter-active {
transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
transition: opacity 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
opacity: 0;
}

.slide-enter-active {
transition: all 0.3s ease;
}

.slide-leave-active {
transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-enter,
.slide-leave-to {
transform: translateX(10px);
opacity: 0;
}
</style>
